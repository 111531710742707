:host {
  display: block;
}

.copy-button {
  @apply absolute right-3 top-3 z-10 h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-neutral-800 ring-1 ring-neutral-300 hover:bg-neutral-100;
  &.copied {
    @apply bg-neutral-700 text-white ring-neutral-300;
  }
}
